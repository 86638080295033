import React, {useContext, useEffect, useState} from 'react';
import { ButtonBar, OrderedButton } from '@jpmuitk/button-bar';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@jpmuitk/dialog';
import { DensityContext } from '@jpmuitk/theme';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { useHistory } from 'react-router-dom';
import {useMainContext} from '../../MainContext/MainContext';
import CustomCombobox from '../../Common/CustomCombobox/CustomCombobox';
import './CountryAndLanguagePopup.scss';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
  baseURLSelector,
  setCancelledFromHome,
  setCurrentLanguages,
  setLanguageCodes, setPopupRenderKey, setShowCountryPopup
} from "../../../store/slice/mansart/mansart";
import { FormField } from '@jpmuitk/form-field';
interface CountryAndLanguagePopupParams {
  isOpen:boolean;
  investorRefreshHandler: () => void;
}
interface ICountryCode {
  name: string;
  code: string;
}
const CountryAndLanguagePopup = ({isOpen = false, investorRefreshHandler}:CountryAndLanguagePopupParams) => {
  const [open, setOpen] = useState(isOpen);
  const [countryAndLanguageData, setCountryAndLanguageData] = useState<any[]>([]);
  const [countryArr, setCountryArr] = useState<string[]>([]);
  const [languageArr, setLanguageArr] = useState<any[]>([]);
  const [languageId, setLanguageId] = useState<number>(Math.floor(Math.random()*100));
  const [nextButtonClass, setNextButtonClass] = useState<string>('disable');
  const dispatch = useAppDispatch();
  const {
    setPopupType,
    selectedCountry,
    selectedLanguage,
    investorType,
    setProfileObject,
    profileObject,
    setSelectedLanguage,
    setSelectedCountry,
    setInvestorType,
    currentLanguage,
    setCurrentTab
  } = useMainContext();
  const baseURL = useAppSelector(baseURLSelector);
  const history = useHistory();
  const getCountries = () => {
    fetch(`${baseURL}/country`)
      .then(res => res.json())
      .then(
        (result) => {
          setCountryAndLanguageData(result);
          const countries:string[] = [];
          result.map(val => {
            countries.push(val.country);
          });
          setCountryArr(countries);
          dispatch(setLanguageCodes(getLanguageCodes(result)))
        },
        (error) => {

        }
      )
  }
  const getLanguageCodes = data => {
    const codeArr:ICountryCode[] = [];
    data.map(value => {
      value.languages.map(object => {
        codeArr.push(object)
      })
    })
    return codeArr;
  }
  const createLanguagesArr = data => {
    const langArr:string[] = [];
    data.map(val => {
      langArr.push(val.name);
    });
    return langArr;
  }
  const getLanguages = (countryAndLanguageData) => {
    countryAndLanguageData?.forEach(value => {
      if (value.country === profileObject.country) {
        setLanguageArr(createLanguagesArr(value.languages));
      }
    })
  }
  useEffect(() => {
    if(baseURL.length > 1) {
      getCountries();
    }
  }, [baseURL]);
  useEffect(() => {
    getLanguages(countryAndLanguageData);
  }, [profileObject.country]);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])
  const handleRequestOpen = () => {
    countryAndLanguageData?.forEach(value => {
      if (value.country === selectedCountry) {
        setLanguageArr(createLanguagesArr(value.languages));
        profileObject.country = selectedCountry;
        setProfileObject(profileObject);
      }
    });
    setLanguageId(Math.floor(Math.random()*100));
  }

  const handleRequestClose = () => {
    setOpen(false);
    dispatch(setShowCountryPopup(false));
    if (selectedCountry === '' || selectedLanguage === '' || investorType === '' ) {
      dispatch(setCancelledFromHome(true));
      history.push('/aboutus');
      setCurrentTab(4);
      setSelectedLanguage('');
      setSelectedCountry('');
      setInvestorType('');
      profileObject.country = '';
      setProfileObject(profileObject);
      profileObject.language = '';
      setProfileObject(profileObject);
    } else {
      setSelectedLanguage(currentLanguage);
    }
    setPopupType('');

  };

  const density = useContext(DensityContext);

  const densityBreakpoint = density === 'touch' ? 'xl' : 'xs';

  const densityDialogWidths = {
    touch: 640,
    low: 600,
    medium: 500,
    high: 500,
  };
  const nextButtonHandler = () => {
    setPopupType('investor');
    investorRefreshHandler();
  }
  const onCountrySelect = (value) => {
    setNextButtonClass('disable');
    profileObject.country = value;
    setProfileObject(profileObject);
    getLanguages(countryAndLanguageData);
    setLanguageId(Math.floor(Math.random()*100));
  }
  const onLanguageSelect = (value) => {
    setSelectedLanguage(value);
    profileObject.language = value;
    setProfileObject(profileObject);
    setNextButtonClass('enable');
  }
  useEffect(() => {
    if(languageArr.length > 0){
      onLanguageSelect(languageArr[0]);
      dispatch(setCurrentLanguages(languageArr));
    }
  },[languageArr]);
  useEffect(()=>{
    if(open){
      handleRequestOpen();
    }

  }, [open, countryAndLanguageData])
  return (
    <>
      <Dialog
        onClose={handleRequestClose}
        open={open}
        width={densityDialogWidths[density]}
        density={'high'}
      >
        <DialogTitle onClose={handleRequestClose} data-testid="country-popup-header">
          Choose Country
        </DialogTitle>
        <DialogContent >
          <ColumnLayout container className='country-and-language-popup'>
            <ColumnLayout item className='country-dropdown'>
              <FormField label="Select Country" id="country-form">
              <CustomCombobox data={countryArr} onSelect={onCountrySelect} initialSelectedItem={selectedCountry} />
              </FormField>
            </ColumnLayout>
          </ColumnLayout>
        </DialogContent>
        <DialogActions>
          <ColumnLayout container className='country-and-language-popup'>
          <ButtonBar stackAtBreakpoint={densityBreakpoint}>
            <OrderedButton variant="cta" onClick={() => {nextButtonHandler()}} className={nextButtonClass}>NEXT</OrderedButton>
          </ButtonBar>
          </ColumnLayout>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CountryAndLanguagePopup;
