import React from 'react';
import { ButtonBar } from '@jpmuitk/button-bar';
import {getCode, getLanguage} from "../../Utils/utils";
import LanguageButton from "./LanguageButton";

interface ILanguageButtonBarParams {
  languages: string[],
  languageCodes: string[],
  currentLanguage: string,
  onLanguageClick: (val: string) => void
}
const LanguageButtonBar = ({languages, languageCodes, currentLanguage,  onLanguageClick}:ILanguageButtonBarParams) => {

  const changeLanguage = (event) => {
    const currentLang = getLanguage(languageCodes, event.target['innerText'].toLowerCase());
    onLanguageClick(currentLang);
  }
  const createButtons = () => {
    return languages.map(val => {
      return <LanguageButton clickHandler={changeLanguage} langCode={getCode(languageCodes, val)} currentLangCode={getCode(languageCodes, currentLanguage)}/>
    })
  }
  return (
    <div >
    <ButtonBar alignLeft stackAtBreakpoint={0} >
      {createButtons()}
    </ButtonBar>
  </div>
  );
};

export default LanguageButtonBar;
