import React, {useEffect, useState} from 'react';
import {ColumnLayout} from "@jpmuitk/column-layout";
import { Icon } from '@jpmuitk/icon';
import "./Footer.scss";
import SecurityComponent from "../Common/SecurityComponent";
import { useHistory } from 'react-router-dom';
//import ContactUs from "../ContactUs/ContactUs";//code is commented as contact us section is not finalize
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
  openContactFormSelector,
  setShowCommonTerms,
  showCommonTermsSelector
} from "../../store/slice/mansart/mansart";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import { Button } from '@jpmuitk/button';
import {useMainContext} from "../MainContext/MainContext";
const Footer = () => {
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openTermsPopup, setOpenTermsPopup] = useState(false);
  const openContactForm = useAppSelector(openContactFormSelector);
  const showCommonTerms = useAppSelector(showCommonTermsSelector);
  const { selectedLanguage } = useMainContext();
  const dispatch = useAppDispatch();
  const history = useHistory();
  /* below code is commented as contact us section is not finalized*/
  /*const openContactUsForm = () => {
    setOpenContactUs(true);
    dispatch(setOpenContactForm(true));
  }*/
  const showTermsPopup = () => {
    dispatch(setShowCommonTerms(true));
  }
  useEffect(() => {
    setOpenContactUs(openContactForm);
  }, [openContactForm]);
  useEffect(() => {
    setOpenTermsPopup(showCommonTerms);
  }, [showCommonTerms]);
  return (
    <ColumnLayout container spacing={5} className="footer" key={String(`${openContactUs} ${openTermsPopup} ${selectedLanguage}`)}>
      {/*<ContactUs isOpen={openContactUs}/>*/}
      {openTermsPopup ? <TermsAndConditions showButtons={false}/> : null}
      <ColumnLayout item className="footer-link external-link" data-test-id="jpm-site"> <a target="_blank" href="https://www.jpmorgan.com/global">JPMorgan.com <Icon name="tear-out" className="external-link-icon"/></a></ColumnLayout>
      <ColumnLayout item className="footer-link external-link" data-test-id="cookies-policy"> <a target="_blank" href="https://www.jpmorgan.com/cookies"><SecurityComponent translationKey="footer.link.cookiepolicy"/> <Icon name="tear-out" className="external-link-icon"/></a></ColumnLayout>
      <ColumnLayout item className="footer-link external-link" data-test-id="privacy-policy"> <a target="_blank" href="https://www.jpmorgan.com/privacy"><SecurityComponent translationKey="footer.link.privacy"/> <Icon name="tear-out" className="external-link-icon"/></a></ColumnLayout>
      <ColumnLayout item className="footer-link footer-tab" onClick={showTermsPopup}><Button variant="secondary" className="footer-buttons" data-test-id="terms-popup"> <SecurityComponent translationKey="footer.link.tandc"/></Button></ColumnLayout>
      <ColumnLayout item className="footer-link footer-tab" onClick={()=>{history.push('/regulatory');}}><Button variant="secondary" className="footer-buttons" data-test-id="regulatory"> <SecurityComponent translationKey="footer.link.regulatoryDisclosure"/> </Button> </ColumnLayout>
      <ColumnLayout item className="footer-link footer-tab" onClick={()=>{history.push('/sustainability');}}> <Button variant="secondary" className="footer-buttons" data-test-id="sustainability"><SecurityComponent translationKey="footer.link.sustainabilityRelatedDisclosures"/></Button></ColumnLayout>
      {/*<ColumnLayout item className="footer-link footer-tab" onClick={openContactUsForm}> <Button variant="secondary" className="footer-buttons">Contact</Button></ColumnLayout> // commenting this button as requirement confirmation is pending*/}
      <ColumnLayout container spacing={5} className="copy-right">
        <SecurityComponent translationKey="footer.copyright"/>
      </ColumnLayout>
    </ColumnLayout>
  );
};

export default Footer;
