import { createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IFundsAndClassesState {
  overviewData: any;
  classData: any;
  selectedFund: string;
  isFundsDetailSection: boolean;
  selectedClassNames: string[];
  selectedPerformance: string[];
  classesList: string[];
  currentFundObject: any;
  showFundDetailPage: boolean;
  performanceStartDate: number;
  performanceEndDate: number;
  allPerformanceStartDate: number;
  selectedDateRange: string;
  selectedPerformanceDateRange: string;
  selectedNavHistoryDateRange: string;
  isCustomDate:boolean;
  currencyList: any;
}
export const initialState: IFundsAndClassesState = {
  overviewData: [],
  classData: [],
  selectedFund: '',
  isFundsDetailSection: false,
  selectedClassNames: [],
  selectedPerformance: [],
  classesList: [],
  currentFundObject: [],
  showFundDetailPage: false,
  performanceStartDate: 0,
  performanceEndDate: 0,
  allPerformanceStartDate: 0,
  selectedDateRange: 'all',
  selectedPerformanceDateRange: 'all',
  selectedNavHistoryDateRange: 'all',
  isCustomDate: false,
  currencyList: []
};
const fundsAndClassesParameters = createSlice({
  name: 'fundsAndClassesParameters',
  initialState,
  reducers: {
    setOverviewData: (state, action: PayloadAction<any>) => {
      state.overviewData = action.payload;
    },
    setClassData: (state, action: PayloadAction<any>) => {
      state.classData = action.payload;
    },
    setSelectedFund: (state, action: PayloadAction<any>) => {
      state.selectedFund = action.payload;
    },
    setIsFundsDetailSection: (state, action: PayloadAction<any>) => {
      state.isFundsDetailSection = action.payload;
    },
    setSelectedClassNames: (state, action: PayloadAction<any>) => {
      state.selectedClassNames = action.payload;
    },
    setSelectedPerformance: (state, action: PayloadAction<any>) => {
      state.selectedClassNames = action.payload;
    },
    setClassesList: (state, action: PayloadAction<any>) => {
      state.classesList = action.payload;
    },
    setCurrentFundObject: (state, action: PayloadAction<any>) => {
      state.currentFundObject = action.payload;
    },
    setShowFundDetailPage: (state, action: PayloadAction<any>) => {
      state.showFundDetailPage = action.payload;
    },
    setPerformanceStartDate: (state, action: PayloadAction<any>) => {
      state.performanceStartDate = action.payload;
    },
    setPerformanceEndDate: (state, action: PayloadAction<any>) => {
      state.performanceEndDate = action.payload;
    },
    setAllPerformanceStartDate: (state, action: PayloadAction<any>) => {
      state.allPerformanceStartDate = action.payload;
    },
    setSelectedDateRange: (state, action: PayloadAction<any>) => {
      state.selectedDateRange = action.payload;
    },
    setSelectedPerformanceDateRange: (state, action: PayloadAction<any>) => {
      state.selectedPerformanceDateRange = action.payload;
    },
    setSelectedNavHistoryDateRange: (state, action: PayloadAction<any>) => {
      state.selectedNavHistoryDateRange = action.payload;
    },
    setIsCustomDate: (state, action: PayloadAction<any>) => {
      state.isCustomDate = action.payload;
    },
    setCurrencyList: (state, action: PayloadAction<any>) => {
      state.currencyList = action.payload;
    },
    resetParameters: () => ({ ...initialState })
  }
});

// actions
export const {
  setOverviewData,
  setClassData,
  setSelectedFund,
  setIsFundsDetailSection,
  setSelectedClassNames,
  setSelectedPerformance,
  setClassesList,
  setCurrentFundObject,
  resetParameters,
  setShowFundDetailPage,
  setPerformanceStartDate,
  setPerformanceEndDate,
  setSelectedDateRange,
  setSelectedPerformanceDateRange,
  setSelectedNavHistoryDateRange,
  setAllPerformanceStartDate,
  setIsCustomDate,
  setCurrencyList
} = fundsAndClassesParameters.actions;

// selectors
const selectSelf = state => state.fundsAndClassesParameters;
export const overviewDataSelector = createDraftSafeSelector(selectSelf, state => state?.overviewData);
export const classDataSelector = createDraftSafeSelector(selectSelf, state => state?.classData);
export const selectedFundSelector = createDraftSafeSelector(selectSelf, state => state?.selectedFund);
export const isFundsDetailSectionSelector = createDraftSafeSelector(selectSelf, state => state?.isFundsDetailSection);
export const selectedClassNamesSelector = createDraftSafeSelector(selectSelf, state => state?.selectedClassNames);
export const selectedPerformanceSelector = createDraftSafeSelector(selectSelf, state => state?.selectedPerformance);
export const classesListSelector = createDraftSafeSelector(selectSelf, state => state?.classesList);
export const currentFundObjectSelector = createDraftSafeSelector(selectSelf, state => state?.currentFundObject);
export const showFundDetailPageSelector = createDraftSafeSelector(selectSelf, state => state?.showFundDetailPage);
export const performanceStartDateSelector = createDraftSafeSelector(selectSelf, state => state?.performanceStartDate);
export const performanceEndDateSelector = createDraftSafeSelector(selectSelf, state => state?.performanceEndDate);
export const allPerformanceStartDateSelector = createDraftSafeSelector(selectSelf, state => state?.allPerformanceStartDate);
export const selectedDateRangeSelector = createDraftSafeSelector(selectSelf, state => state?.selectedDateRange);
export const selectedPerformanceDateRangeSelector = createDraftSafeSelector(selectSelf, state => state?.selectedPerformanceDateRange);
export const selectedNaveHistoryDateRangeSelector = createDraftSafeSelector(selectSelf, state => state?.selectedNavHistoryDateRange);
export const isCustomDateSelector = createDraftSafeSelector(selectSelf, state => state?.isCustomDate);
export const currencyListSelector = createDraftSafeSelector(selectSelf, state => state?.currencyList);
// reducer
export default fundsAndClassesParameters.reducer;
