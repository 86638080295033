import React, {useState} from 'react';
import { FormField } from '@jpmuitk/form-field';
import { ComboBox } from '@jpmuitk/combo-box';
import '@jpmuitk/style/css/jpmuitk.css';
interface CustomComboboxParam {
  width?: number,
  data: any[],
  onSelect?: (val: string) => void,
  label?: string,
  initialSelectedItem?: string;
  selectedItem?: string;
}

const CustomCombobox = ({ width = 120, data = [], onSelect, label= '', initialSelectedItem = '', selectedItem }: CustomComboboxParam) => {
  const [selectedValue, setSelectedValue] = useState(initialSelectedItem);
  const [previouslySelectedItem, setPreviouslySelectedItem] = useState(initialSelectedItem);
  const handleChange = (item) => {
    console.log('selection changed', item);
  };

  const handleSelect = (_, selectedItem) => {
    onSelect && onSelect(selectedItem);
    setSelectedValue(selectedItem)
    setPreviouslySelectedItem(selectedItem)
  };

  const handleOnFocus = () => {
    setSelectedValue("");
  }

  const handleBlur = () => {
    setSelectedValue(previouslySelectedItem);
  }
  return (
      <FormField label={label} style={{ maxWidth: 120 }}>
        <ComboBox
          onChange={(e, value) => {handleChange(value);}}
          onSelect={handleSelect}
          source={data}
          listWidth={width}
          initialSelectedItem={initialSelectedItem}
          onFocus={handleOnFocus}
          onInputBlur={handleBlur}
          selectedItem={selectedValue}
        />
      </FormField>
  );
}

export default CustomCombobox;
