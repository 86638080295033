import React from 'react';
import { useTranslation } from "react-i18next";
interface ISecurityComponent {
  translationKey: string
}
const SecurityComponent = ({translationKey}: ISecurityComponent) => {
  const DOMPurify = require('dompurify')(window);
  const { t } = useTranslation();
  return (
    <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(t(translationKey))}}/>
  )
}

export default SecurityComponent;
