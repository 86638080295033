
export const convertToCamelCase = (str: string) => {
  str = str.replace('-', " ");
  const wordsArr = str.split(" ");
  const newStrArr = wordsArr.map((val) => {
    return val.toLowerCase();
  });
  const newStr = newStrArr.join(" ");
  return newStr.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toLowerCase() : word.toUpperCase(); }).replace(/\s+/g, '');
}

export const replaceNullUndefinedValue = (value) => {
  if (value === null || value === undefined ) {
    return "-";
  } else {
    return value;
  }
}

export const decodeHTML = (html) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

export const getCode = (languageCodes, currentLanguage) => {
  let code= 'en';
  languageCodes.map(value => {
    if( value.name === currentLanguage) {
      code = value.code;
    }
  })
  return code;
}
export const getLanguage = (languageCodes, currentCode) => {
  let code= 'english';
  languageCodes.map(value => {
    if( value.code === currentCode) {
      code = value.name;
    }
  })
  return code;
}

export const removeDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

export const formatNumberPlace = (number, showDecimal = true) => {
  if (number) {
    const updatedNumber = number.toString().split('.');
    const moreThanOne = updatedNumber[0];
    const lessThanOne = updatedNumber[1];
    const removeDecimal = moreThanOne?.length >= 4 ? '' : lessThanOne ? '.' + lessThanOne : '';
    const addDecimal = lessThanOne ? '.' + lessThanOne : '';
    return (
      (moreThanOne === Infinity ? '-' : moreThanOne.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) +
      (showDecimal ? addDecimal : removeDecimal)
    );
  } else {
    return number === Infinity ? '-' : number;
  }
};
