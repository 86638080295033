import React from 'react';
import { Card } from '@jpmuitk/card';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { color } from '@jpmuitk/style'
import './RegulatoryDocumentsCard.scss';
import {Icon} from "@jpmuitk/icon";
import {useAppSelector} from "../../../store/hooks";
import {baseURLSelector} from "../../../store/slice/mansart/mansart";
import { Button } from '@jpmuitk/button';
interface IRegulatoryDocumentCardParams{
  heading: string;
  description: string;
  downloadUrl: string;
  documentType: string;
  id: number;
}
const RegulatoryDocumentsCard = ({heading, description, downloadUrl, documentType, id}:IRegulatoryDocumentCardParams) => {
  const DOMPurify = require('dompurify')(window);
  const baseURL = useAppSelector(baseURLSelector);
  //uncomment below for local or testing with dummy data
  //id = 3938;
  const url = baseURL.indexOf('3001') === -1 ? `${baseURL}/content/${id}` : 'https://jpmorganmansart.com/mansart/content/3566';
  const downloadFiles = (url, name, openInNewTab= false) => {
    fetch(url).then((t) => {
      return t.blob().then((b) => {
        if (!openInNewTab) {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(b);
          a.setAttribute('download', `${name}.${documentType}`);
          a.setAttribute('target', '_self');
          a.click()
        }else {
          const  file = new Blob([b], {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        }
      });
    })
  }
  return (
    <Card className="card-style">
      <ColumnLayout container className="regulatory-document-card">
        <div>
          <h3>{heading}</h3>
          <p className="regulatory-card-description"><span
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description)}}/></p>
          <ColumnLayout container spacing={5}>
            <ColumnLayout item>
              <Button data-test-id="download-file" variant="secondary" onClick={() => {
                downloadFiles(url, heading, false)}
              }><Icon name="download" className="external-link-icon"/>
              DOWNLOAD FILE</Button>
            </ColumnLayout>
            {documentType === 'pdf' ?
              <ColumnLayout item>
                <Button data-test-id="open-file" variant="secondary" onClick={() => {
                  downloadFiles(url, heading, true)}
                }><Icon name="tear-out" className="external-link-icon"/>OPEN FILE</Button>
              </ColumnLayout>
              : null}
          </ColumnLayout>
        </div>
      </ColumnLayout>
    </Card>
  )
};

export default RegulatoryDocumentsCard;
