import { configureStore } from '@reduxjs/toolkit';

import appReducer from './slice/app';
import fundsAndClassesParametersReducers from '../store/slice/fundAndclasses/fundAndclasses';
import mansartParametersReducers from '../store/slice/mansart/mansart';

export const store = configureStore({
  reducer: {
    app: appReducer,
    fundsAndClassesParameters: fundsAndClassesParametersReducers,
    mansartParameters: mansartParametersReducers
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
