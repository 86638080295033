import React, {createContext, useContext, useEffect, useState} from 'react';
import i18n from '../../i18n';
interface IProfile {
  investor?: string,
  country?: string,
  language?: string
}
interface ICountryCode {
  name: string;
  code: string;
}
export interface MainContextState {
  selectedCountry: string;
  setSelectedCountry: (value: string) => void;
  selectedLanguage: string;
  setSelectedLanguage: (value: string) => void;
  investorType: string;
  setInvestorType: (value: string) => void;
  currentTab: number;
  setCurrentTab: (value: number) => void;
  popupType: string;
  setPopupType: (value: string) => void;
  profileObject: IProfile;
  setProfileObject: (value: IProfile) => void;
  currentLanguage: string;
  setCurrentLanguage: (value: string) => void;
}

interface MainContextProps {
  children: React.ReactNode;
}

export const initialMainContextState = {};

const context = createContext<MainContextState>(initialMainContextState as MainContextState);
const { Provider, Consumer} = context;

const MainContext = ({ children }: MainContextProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [investorType, setInvestorType] = useState<string>('');
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [popupType, setPopupType] = useState<string>('country');
  const [profileObject, setProfileObject] = useState<IProfile>({investor: '', country: '', language: ''});
  const [currentLanguage, setCurrentLanguage] = useState<string>('');

  useEffect(() => {
    let lang = selectedLanguage?.toLowerCase();
    if (lang === '') {
      lang = 'english'
    }
    i18n.changeLanguage(lang);
  }, [selectedLanguage])
  return(
    <Provider value={{
      selectedLanguage,
      setSelectedLanguage,
      selectedCountry,
      setSelectedCountry,
      investorType,
      setInvestorType,
      currentTab,
      setCurrentTab,
      popupType,
      setPopupType,
      profileObject,
      setProfileObject,
      currentLanguage,
      setCurrentLanguage
    }}>{children}</Provider>
  )
};

MainContext.Consumer = Consumer;
export const useMainContext = () => useContext<MainContextState>(context);
export default MainContext;
