import React, { ReactNode, useEffect, useState } from 'react';
import { Banner } from '@jpmuitk/banner';
import { ColumnLayout } from '@jpmuitk/column-layout';
import './MessageBanner.scss';

export enum bannerState {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning'
}
export interface MessageBannerProps {
  message?: string;
  messageType?: bannerState;
  show?: boolean;
  closeHandler?: () => void;
  children?: ReactNode;
}

const MessageBanner = ({ messageType, message, show, closeHandler, children }: MessageBannerProps) => {
  const [showBanner, setShowBanner] = useState(show);

  const handleClose = () => {
    if (closeHandler) {
      closeHandler();
    }
    setShowBanner(false);
  };
  useEffect(() => {
    setShowBanner(show);
  }, [show]);
  return showBanner ? (
    <ColumnLayout className="message-banner">
      <Banner
        onClose={handleClose}
        state={messageType || bannerState.INFO}
        className="banner-position"
        announcement={message || ''}
      >
        {children || message || ''}
      </Banner>
    </ColumnLayout>
  ) : null;
};

export default MessageBanner;
