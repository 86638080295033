import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
// import { AriaAnnouncerProvider } from '@jpmuitk/aria-announcer';
import { ThemeProvider, createToolkitTheme } from '@jpmuitk/theme';
import '@jpmuitk/style/css/jpmuitk.css';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from "react-cookie";
const theme = createToolkitTheme('light');

function Root() {

  return (
    <HashRouter>
        <ThemeProvider theme={theme}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </ThemeProvider>
    </HashRouter>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
