import React, {useEffect, useState} from 'react';
import { Icon } from '@jpmuitk/icon';
import { ToolbarButton } from "@jpmuitk/toolbar";
import { Button } from "@jpmuitk/button";
import {useMainContext} from "../MainContext/MainContext";
import {ColumnLayout} from "@jpmuitk/column-layout";
import './InvestorAndRegionEditor.scss';
import {
setShowCountryPopup
} from "../../store/slice/mansart/mansart";
import {useAppDispatch} from "../../store/hooks";
interface CountryAndLanguageEditorParans {
  country: string;
  language?: string;
}
const InvestorAndRegionEditor = ({country, language}: CountryAndLanguageEditorParans) => {
  const [investorText, setInvestorText] = useState('');
  const [countryText, setCountryText] = useState('');
  const {setPopupType, investorType } = useMainContext();
  const dispatch = useAppDispatch();
  useEffect(() => {
    let strInvestor = 'SELECT  INVESTOR AND COUNTRY';
    if (investorType !== '') {
      strInvestor = `${investorType} INVESTOR,`;
    }
    setInvestorText(strInvestor);
    let strCountry = 'COUNTRY';
    if (country !== '' || language !== '') {
      strCountry = `${country}`;
    }
    setCountryText(strCountry);
  }, [country, language]);

  const editCountryHandler = () => {
    dispatch(setShowCountryPopup(true));
    setPopupType('country');
  }
  return (
    <ToolbarButton>
        <Button variant="secondary" onClick={() => {editCountryHandler();}}>
          <ColumnLayout container className="investor-and-region-editor">
            <ColumnLayout item className="investor-text">{investorText}</ColumnLayout>
            <ColumnLayout item>{countryText} </ColumnLayout>
            <ColumnLayout item className="edit-icon"><Icon name="edit" /></ColumnLayout>
          </ColumnLayout>
        </Button>
    </ToolbarButton>
  )
};


export default InvestorAndRegionEditor;
