import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { ColumnLayout } from '@jpmuitk/column-layout';
import AppNav from './Components/AppNav/AppNav';
import MainContext from "./Components/MainContext/MainContext";
import Loader from "./Components/Common/Loader/Loader";
import {store} from "./store/main";
import { Provider } from 'react-redux';
import './styles/main.scss';
const App = () => (
  <ColumnLayout container className="app-container">
    <MainContext>
      <Suspense fallback={<Loader/>}>
        <Provider store={store}>
        <AppNav />
        </Provider>
      </Suspense>
    </MainContext>
  </ColumnLayout>
);

App.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default App;
