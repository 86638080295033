import React from 'react';
import { OrderedButton } from '@jpmuitk/button-bar';
import './LanguageButton.scss';

interface ILanguageButton {
  langCode: string,
  currentLangCode: string
  clickHandler: (event) => void
}
const LanguageButton = ({langCode,currentLangCode,clickHandler }:ILanguageButton) => {
  const onButtonClick = (event) => {
    clickHandler && clickHandler(event);
  }
  return (
    <div className="language-button">
      <OrderedButton onClick={(event) => {onButtonClick(event)}}  variant="secondary" className={langCode === currentLangCode ? "add-boald-text" : ""}>{langCode}</OrderedButton>
    </div>

  );
};

export default LanguageButton;
