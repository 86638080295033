import React, {useEffect, useState} from 'react';
import { AppHeaderNext } from '@jpmuitk/app-header';
import InvestorAndRegionEditor from "../InvestorAndRegionEditor/InvestorAndRegionEditor";
import {useMainContext} from "../MainContext/MainContext";
import CountryAndLanguagePopup from "../Home/CountryAndLanguagePopup/CountryAndLanguagePopup";
import InvestorTypePopup from "../Home/InvertorTypePopup/InvestorTypePopup";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import {
  cancelledFromHomeSelector,
  popupRenderKeySelector,
  setPopupRenderKey,
  setCancelledFromHome,
  showCountryPopupSelector, setShowCountryPopup
} from "../../store/slice/mansart/mansart";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import "./Header.scss";
const Header = () => {
  const {investorType, selectedLanguage, selectedCountry, popupType, profileObject} = useMainContext();
  const [showInvestorPopup, setShowInvestorPopup] = useState(true);
  const dispatch = useAppDispatch();
  const popupRenderKey = useAppSelector(popupRenderKeySelector);
  const cancelledFromHome = useAppSelector(cancelledFromHomeSelector);
  const showCountryPopup = useAppSelector(showCountryPopupSelector);
  const actionSource = [
    { itemRenderer:  () => InvestorAndRegionEditor({country: selectedCountry})}
  ];
  const refreshInvestorEdit = () => {
    const val = Math.floor(Math.random()*100);
    dispatch(setPopupRenderKey(val));
  }
  const handleActionItemClick = () => {
    dispatch(setCancelledFromHome(false));
    refreshInvestorEdit();
  }

  useEffect(() => {
    let value = true;
    if (investorType === '' ) {
      value = true;
    }
    setShowInvestorPopup(value);
    refreshInvestorEdit();
  }, [investorType]);

  return (
    <div key={popupRenderKey} className="header">
      {(popupType === 'country' || profileObject.country === '' || profileObject.language === '') && !cancelledFromHome && popupType !== 'investor' && popupType !== 'termsAndConditions' && popupType !== '' ?
          <CountryAndLanguagePopup isOpen={showCountryPopup} investorRefreshHandler={refreshInvestorEdit}/> :
        (popupType === 'investor' || profileObject.investor === '')  && !cancelledFromHome && popupType !== ''?
          <InvestorTypePopup isOpen={showInvestorPopup}/> : popupType === 'termsAndConditions' ?
            <TermsAndConditions/> : null}
      <AppHeaderNext
        actionSource={actionSource}
        onClick={handleActionItemClick}
        data-testid="country-and-investor-edit"
        density="touch"
        className="app-header"
      />
    </div>

  );
};

export default Header;
