import React, {useState, Suspense, useEffect} from 'react';
import { Route, Switch, withRouter, Redirect} from 'react-router-dom';
import { Tabs } from '@jpmuitk/tabs';
import { ColumnLayout } from '@jpmuitk/column-layout';
import Header from "../Header/Header";
import {ThemeProvider } from '@jpmuitk/theme';
import './AppNav.scss';
import {darkTheme} from "../../styles/theme";
import {useMainContext} from "../MainContext/MainContext";
import Footer from "../Footer/Footer";
import { store } from '../../store/main';
import { Provider } from 'react-redux';
import { useTranslation } from "react-i18next";
import Loader from "../Common/Loader/Loader";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {setBaseURL, setCancelledFromHome, setPopupRenderKey, setShowCountryPopup} from "../../store/slice/mansart/mansart";
import RegulatoryAndDisclosures from "../RegulatoryAndDisclosures/RegulatoryAndDisclosures";
import {
  selectedPerformanceSelector,
  setIsFundsDetailSection, setSelectedClassNames,
  setShowFundDetailPage
} from "../../store/slice/fundAndclasses/fundAndclasses";
import { useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";

const Home = React.lazy(() => import('../Home/Home'));
const About = React.lazy(() => import('../About/About'));
const Sustainability = React.lazy(() => import('../Sustainability/Sustainability'));
const Funds = React.lazy(() => import('../Funds/Funds'));



const MansartRoute = ({ children, ...rest }) => {
  return (
    <Route
      children={undefined} {...rest}
      render={() => {
        return children;
      }}    />
  );
};

const AppNav = ({ history, location }) => {
  const dispatch = useAppDispatch();
  const {setCurrentTab, currentTab, investorType, setSelectedLanguage, setSelectedCountry, setInvestorType, setPopupType} = useMainContext();
  const [selectedTab, setSelectedTab] = useState(currentTab);
  const { t } = useTranslation();
  const TABS = [t('navbar.link.home'), t('navbar.link.funds'), 'Sustainability', 'Regulatory', t('navbar.link.aboutus')];
  const tabs = TABS;
  const tabs_name = ['Home', 'Funds', 'Sustainability', 'Regulatory', 'About us' ];
  const tab_ids = ['Home', 'Funds', 'Sustainability', 'Regulatory', 'About us' ];
  const selectedPerformance = useAppSelector(selectedPerformanceSelector)
  const   [cookies, setCookies]  = useCookies(['selectedCountry', 'selectedLanguage', 'investorType', 'selectedTab']);
  const pathnames = location.pathname.split('/');
  const [ locationKeys, setLocationKeys] = useState<any[]>([]);
  const hstry = useHistory()
  useEffect(() => {
    setSelectedTab(currentTab);
  }, [currentTab]);

  useEffect(() => {
    setCurrentTab(selectedTab);
    let expires = new Date();
    expires.setTime(expires.getTime() + 10800000);
    setCookies('selectedTab', selectedTab, {path:"/", expires});
  }, [selectedTab]);
  if (pathnames && pathnames.length > 0 && location.pathname !== '/home') {
    const route = pathnames.slice(2)[0];
    const index = tabs.findIndex(item => item?.toLowerCase() === route?.toLowerCase());
    if (index !== -1 && selectedTab !== index && route) {
      setSelectedTab(index);
    }
  }

  const setRoute = (event: React.ChangeEvent<{}>, route: string) => {
    if(route === t('navbar.link.funds')){
      dispatch(setShowFundDetailPage(false));
      dispatch(setIsFundsDetailSection(false));
      dispatch(setSelectedClassNames([]));
    }
    if (investorType === '' && route !== 'Home' && route !== 'Sustainability' && route !== 'About Us') {
      dispatch(setCancelledFromHome(false));
      dispatch(setShowCountryPopup(true));
      dispatch(setPopupRenderKey(Math.floor(Math.random()*100)));
      setPopupType('country');
      dispatch(setShowCountryPopup(true));
    } else {
      setSelectedTab(tabs.findIndex(tab => tab === route));
      const current = route;
      history.push(`/${tabs_name[tabs.indexOf(current)].split(" ").join("").toLowerCase()}`);
    }

  };
  const getId = (tab) => {
    let currentIndex = 0;
    tab_ids.map((val, index) => {
      if (val.split(" ").join("").toLowerCase() === tab) {
        currentIndex = index;
      }
    })
    return currentIndex
  }
  useEffect(() => {
    const base_URL = window.location.origin.indexOf('3000') === -1  ? window.location.origin+"/web" : "http://localhost:3001";
    dispatch(setBaseURL(base_URL));
    if (cookies?.selectedCountry?.length > 0 && cookies?.selectedLanguage?.length > 0 && cookies?.investorType?.length > 0) {
      setSelectedLanguage(cookies?.selectedLanguage);
      setSelectedCountry(cookies?.selectedCountry);
      setInvestorType(cookies?.investorType);
    }
  },[]);

  useEffect(()=> {
    return hstry.listen(location => {
      if (hstry.action === 'PUSH') {
        setLocationKeys([location.key]);
        setSelectedTab(getId(location.pathname.split("/")[1]));
      }
      if (hstry.action === 'POP') {
        setLocationKeys(([_, ...keys])=> keys)
        setSelectedTab(getId(location.pathname.split("/")[1]));
      } else {
        setLocationKeys((keys) => [ location.key, ...keys])
      }
    })
  }, [locationKeys, ]);
  useEffect(()=> {
    setSelectedTab(getId(location.pathname.split("/")[1]));
    if (cookies?.selectedTab === "1" && cookies?.investorType === "" && cookies?.selectedLanguage === "" && cookies?.selectedCountry === "" ) {
      dispatch(setCancelledFromHome(false));
      dispatch(setShowCountryPopup(true));
      dispatch(setPopupRenderKey(Math.floor(Math.random()*100)));
    }
  }, [cookies, location.pathname]);
  return (
    <Suspense fallback={<Loader/>}>
      <ColumnLayout container item id="app-nav" className="app-nav">
        <ColumnLayout item xs={12}>
            <div className="app-nav-wrapper">
              <div className="app-nav-tabs">
                <div className="app-nav-content">
                  <div className="lef-title">
                    <Header/>
                  </div>
                  <ThemeProvider theme={darkTheme}>
                  <ColumnLayout container className='nav-container' id="top-nav">
                    <ColumnLayout item xs={1} alignContent="flex-start">
                      <div className="mansart-logo">Mansart</div>
                    </ColumnLayout>
                    <ColumnLayout item xs={10} alignContent="flex-end" className="app-nav">
                      <div className="lef" id="main-header-tabs">
                        <Tabs key="topNav" value={selectedTab} tabs={tabs} onChange={setRoute} className="tabs" height={50} centered noBorder tabIds={tab_ids} />
                      </div>
                    </ColumnLayout>
                  </ColumnLayout>
                  </ThemeProvider>
                </div>
              </div>
            </div>
        </ColumnLayout>
      </ColumnLayout>
      <Suspense fallback={<Loader/>}>
        <Provider store={store}>
      <ColumnLayout container item id="app-content" className="flex-1">
          <Switch>

            <Route path="/home" component={Home}/>
              <MansartRoute exact path="/" >
              <Redirect to="/home" />
              </MansartRoute>
              <MansartRoute exact path="/" >
              <Redirect to="/home" />
              </MansartRoute>
              <MansartRoute path="/funds" >
              <Funds />
              </MansartRoute>
              <MansartRoute path="/sustainability" >
              <Sustainability />
              </MansartRoute>
              <MansartRoute path="/aboutus" >
              <About />
              </MansartRoute>
              <MansartRoute path="/regulatory" >
              <RegulatoryAndDisclosures />
              </MansartRoute>
          </Switch>
      </ColumnLayout>
        <ColumnLayout container justify="center" className="footer-container">
          <Footer/>
        </ColumnLayout>
        </Provider>
      </Suspense>
    </Suspense>
  );
};

export default withRouter(AppNav);

