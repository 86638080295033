import React, {useContext, useEffect, useState} from 'react';
import { ButtonBar, OrderedButton } from '@jpmuitk/button-bar';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@jpmuitk/dialog';
import { DensityContext } from '@jpmuitk/theme';
import {useMainContext} from "../MainContext/MainContext";
import { useHistory } from 'react-router-dom';
import {Trans, useTranslation} from "react-i18next";
import "./TermsAndConditions.scss";
import SecurityComponent from "../Common/SecurityComponent";
import {ColumnLayout} from "@jpmuitk/column-layout";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
  isRetailTermsSelector, currentLanguagesSelector,
  setCancelledFromHome,
  setIsRetailTerms,
  setShowCommonTerms, languageCodesSelector, setShowCountryPopup
} from "../../store/slice/mansart/mansart";
import {
  setIsFundsDetailSection,
  setSelectedClassNames,
  setShowFundDetailPage
} from "../../store/slice/fundAndclasses/fundAndclasses";
import LanguageButtonBar from "./LanguageButtonBar/LanguageButtonBar";

interface ITermsAndConditions {
  showButtons?: boolean;
}
const TermsAndConditions = ({showButtons = true}:ITermsAndConditions ) => {
  const [open, setOpen] = useState(true);
  const isRetailTerms = useAppSelector(isRetailTermsSelector);
  const {
    setPopupType,
    selectedCountry,
    selectedLanguage,
    investorType,
    setInvestorType,
    setSelectedCountry,
    setSelectedLanguage,
    profileObject,
    setProfileObject,
    setCurrentLanguage,
    currentLanguage,
    setCurrentTab
  } = useMainContext();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleRequestClose = () => {
    dispatch(setShowCountryPopup(false));
    setOpen(false);
    setPopupType('');
    if ((selectedCountry === '' || selectedLanguage === '' || investorType === '') && showButtons ) {
      dispatch(setCancelledFromHome(true));
      history.push('/aboutus');
      setCurrentTab(4);
      setSelectedLanguage('');
      setSelectedCountry('');
      setInvestorType('');
      profileObject.country = '';
      setProfileObject(profileObject);
      profileObject.language = '';
      setProfileObject(profileObject);
      profileObject.investor = '';
      setProfileObject(profileObject);
    } else {
      setSelectedLanguage(currentLanguage);
    }
    dispatch(setIsRetailTerms(false));
    dispatch(setShowCommonTerms(false));
  };
  const languagesArr = useAppSelector(currentLanguagesSelector);
  const languageCodes = useAppSelector(languageCodesSelector);
  const density = useContext(DensityContext);

  const densityBreakpoint = density === 'touch' ? 'xl' : 'xs';

  const acceptHandler = () => {
    if (profileObject.investor && profileObject.language && profileObject.country) {
      setInvestorType(profileObject.investor);
      setSelectedLanguage(profileObject.language);
      setSelectedCountry(profileObject.country);
      setCurrentLanguage(profileObject.language);
    }
    setOpen(false);
    setPopupType('');
    dispatch(setShowFundDetailPage(false));
    dispatch(setIsFundsDetailSection(false));
    dispatch(setSelectedClassNames([]));
    history.push('/funds');
    setCurrentTab(1);
  }
  const onLanguageSelect = (value:string) => {
    setSelectedLanguage(value);
    profileObject.language = value;
    setProfileObject(profileObject);
  }
  return (
    <>
      <Dialog
        onClose={handleRequestClose}
        open={open}
        data-testid="terms-and-conditions-popup"
      >
        <DialogTitle onClose={handleRequestClose} className="terms-title">
          <ColumnLayout container justify={"space-between"} xs={12}>
            <ColumnLayout item >
              <span className="terms-heading"><SecurityComponent translationKey={`terms.heading`}/></span>
            </ColumnLayout>
            <ColumnLayout item className="language-selector">
                <LanguageButtonBar languages={languagesArr} languageCodes={languageCodes} onLanguageClick={onLanguageSelect} currentLanguage={selectedLanguage}/>
            </ColumnLayout>
          </ColumnLayout>
        </DialogTitle>
        <DialogContent>
          <SecurityComponent translationKey="terms.text"/>
          <ColumnLayout container className="terms-and-conditions">
          <div className="bottom-text " key={String(isRetailTerms)}>
            {isRetailTerms && showButtons ? <SecurityComponent translationKey={`homepage.acceptance.${((profileObject?.country)?.split(" ").join("_"))?.toLowerCase()}.ri`}/> : null }
          </div>
          </ColumnLayout>
        </DialogContent>
        <DialogActions>
          <ButtonBar stackAtBreakpoint={densityBreakpoint} className="terms-and-conditions" style={{display: showButtons ? 'block' : 'none'}}>
            <OrderedButton variant="cta" onClick={acceptHandler} data-testid="terms-accept-button"><SecurityComponent translationKey="homepage.acceptance.button.accept"/></OrderedButton>
            <OrderedButton variant="secondary" onClick={handleRequestClose} className="decline-button" data-testid="decline-button"><SecurityComponent translationKey="homepage.acceptance.button.decline"/></OrderedButton>
          </ButtonBar>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default TermsAndConditions;
