import React, {useEffect, useState, useRef} from "react";
import { ColumnLayout } from '@jpmuitk/column-layout';
import "./RegulatoryAndDisclosures.scss";
import SecurityComponent from "../Common/SecurityComponent";
import {useAppSelector} from "../../store/hooks";
import {baseURLSelector} from "../../store/slice/mansart/mansart";
import RegulatoryDocumentsCard from "./RegulatoryDocumentsCard/RegulatoryDocumentsCard";
import {Spinner} from "@jpmuitk/spinner";
import {Card} from "@jpmuitk/card";
import { Link } from '@jpmuitk/link';
import {decodeHTML} from "../Utils/utils";
import MessageBanner, {bannerState} from "../Common/MessageBanner/MessageBanner";
const RegulatoryAndDisclosures = () => {
  const [regulatoryData, setRegulatoryData] = useState<any>();
  const baseURL = useAppSelector(baseURLSelector);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const getRegulatoryData = () => {
    let url = baseURL.indexOf('3001') === -1 ? `${baseURL}/disclosure/regulatory` : `${baseURL}/regulatory`;
    if(baseURL === "") {
      url = `web/disclosure/regulatory`
    }
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.errors || result.error) {
            setShowErrorMessage(true);
            setIsError(true);
          } else {
            setShowErrorMessage(false);
            setIsError(false);
            setRegulatoryData(result);
            setShowSpinner(false);
          }
        },
        (error) => {
          setShowErrorMessage(true);
          setIsError(true);
        }
      );
  };
  const errorMessageClose = () => {
    setShowErrorMessage(false);
  }
  const getPoliciesCardsList = () => {
    return regulatoryData?.policiesAndStatements.map((value, index) => {
      return <div className="regulatory-grid-item ">
        <RegulatoryDocumentsCard description={decodeHTML(value.description)} heading={value.heading} key={value.heading} documentType={value.type} downloadUrl={value.downloadUrl} id={value.id}/>
      </div>

    })
  }
  const getTradeCardsList = () => {
    return regulatoryData?.tradeExecutionReports.map((value, index) => {
      return <div className="regulatory-grid-item ">
        <RegulatoryDocumentsCard description={decodeHTML(value.description)} heading={value.heading} key={value.heading} documentType={value.type} downloadUrl={value.downloadUrl} id={value.id}/>
      </div>

    })
  }
  const getLinksList = () => {
    return regulatoryData?.links.map(value => {
      return  <div style={{marginBottom: "20px"}}><Link href={value.url} target="_blank">{value.name}</Link></div>
    });
  }
  const getLInksCard = () => {
    return <div className="regulatory-grid-item ">
      <Card className="card-style">
        {getLinksList()}
      </Card>
    </div>
  }
  useEffect(()=> {
    getRegulatoryData();
  }, [])
  if (isError) {
    return (
      <ColumnLayout container className="regulatory-and-disclosures">
        <div className="error-message-container">
          <MessageBanner message="Something went wrong. Please try after sometime" messageType={bannerState.ERROR} show={showErrorMessage} closeHandler={errorMessageClose}/>
        </div>
      </ColumnLayout>
    )
  }
  return (
    <ColumnLayout container className="regulatory-and-disclosures">
      <ColumnLayout item className="full-width">
        <div  className="regulatory-image"><p className="heading"><SecurityComponent translationKey="page.title.regulatoryDisclosure"/></p></div>
      </ColumnLayout>
      {showSpinner ? <Spinner className="pre-loader"/> : <ColumnLayout container className="regulatory-cards-container">
        <ColumnLayout item className="section-headings"><h3>Policies and Statements</h3></ColumnLayout>
        <ColumnLayout item className="regulatory-content">
          <ColumnLayout item className="regulatory-grid-container">
            {getPoliciesCardsList()}
          </ColumnLayout>
        </ColumnLayout>
      </ColumnLayout>}
      {showSpinner ? null : <ColumnLayout container className="regulatory-trade-cards-container">
        <ColumnLayout item className="section-headings"><h3>Trade Execution Reports</h3></ColumnLayout>
        <ColumnLayout item className="regulatory-content" style={{width: "100%"}}>
          <ColumnLayout item className="regulatory-grid-container">
            {getTradeCardsList()}
          </ColumnLayout>
        </ColumnLayout>
      </ColumnLayout>}
      {showSpinner ? null : <ColumnLayout container className="regulatory-links-cards-container">
        <ColumnLayout item className="section-headings"><h3>External Website Links</h3></ColumnLayout>
        <ColumnLayout item className="regulatory-content" style={{width: "100%"}}>
          <ColumnLayout item className="regulatory-grid-container">
            {getLInksCard()}
          </ColumnLayout>
        </ColumnLayout>
      </ColumnLayout>}
    </ColumnLayout>
  );
};

export default RegulatoryAndDisclosures;
