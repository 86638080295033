import React, {useContext, useEffect, useState} from 'react';
import { ColumnLayout } from '@jpmuitk/column-layout';
import { Button } from '@jpmuitk/button';
import {
  Dialog,
  DialogTitle,
  DialogContent
} from '@jpmuitk/dialog';
import { DensityContext } from '@jpmuitk/theme';
import { useHistory } from 'react-router-dom';
import './InvestorTypePopup.scss';
import {useMainContext} from "../../MainContext/MainContext";
import {setIsRetailTerms, setCancelledFromHome, setShowCountryPopup} from "../../../store/slice/mansart/mansart";
import {useAppDispatch} from "../../../store/hooks";
interface InvestorTypePopupParams {
  isOpen:boolean;
}
const InvestorTypePopup = ({isOpen = true}:InvestorTypePopupParams) => {
  const [open, setOpen] = useState(isOpen);
  const dispatch = useAppDispatch();
  const {
    setPopupType,
    setInvestorType,
    selectedCountry,
    selectedLanguage,
    investorType,
    profileObject,
    setProfileObject,
    setSelectedCountry,
    setSelectedLanguage,
    currentLanguage,
    setCurrentTab
  } = useMainContext();
  const history = useHistory();
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])
  // const handleRequestOpen = () => setOpen(true);

  const handleRequestClose = () => {
    dispatch(setShowCountryPopup(false));
    setOpen(false);
    if (selectedCountry === '' || selectedLanguage === '' || investorType === '' ) {
      dispatch(setCancelledFromHome(true));
      history.push('/aboutus');
      setCurrentTab(4);
      setSelectedLanguage('');
      setSelectedCountry('');
      setInvestorType('');
      profileObject.country = '';
      setProfileObject(profileObject);
      profileObject.language = '';
      setProfileObject(profileObject);
      profileObject.investor = '';
      setProfileObject(profileObject);
    } else {
      setSelectedLanguage(currentLanguage);
    }
    setPopupType('');
  };

  const density = useContext(DensityContext);

  const densityDialogWidths = {
    touch: 640,
    low: 600,
    medium: 500,
    high: 500,
  };
  const investorTypeHandler = (value) => {
    // setInvestorType(value);
    setOpen(false);
    setPopupType('termsAndConditions');
    profileObject.investor = value;
    setProfileObject(profileObject);
    if (value === 'RETAIL') {
      dispatch(setIsRetailTerms(true));
    } else {
      dispatch(setIsRetailTerms(false));
    }

  }

  return (
    <ColumnLayout container>
      <Dialog
        onClose={handleRequestClose}
        open={open}
        width={densityDialogWidths[density]}
      >
        <DialogTitle onClose={handleRequestClose}>
          Choose an Investment Profile
        </DialogTitle>
        <DialogContent className="investor-type-popup button-container">
          <Button variant="cta" onClick={() => {investorTypeHandler('RETAIL')}}>RETAIL INVESTOR</Button>
          <Button variant="cta" onClick={() => {investorTypeHandler('PROFESSIONAL')}} className="professional-investor-button">PROFESSIONAL INVESTOR</Button>
        </DialogContent>
      </Dialog>
    </ColumnLayout>
  );
};

export default InvestorTypePopup;
