import { createDraftSafeSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ICountryCode {
  name: string;
  code: string;
}
interface IRhp{
  currentRhp: string;
  displayRhp: string;
}
interface IMansartState {
  baseURL: string;
  tabsIndicatorColor: string;
  openContactForm: boolean;
  isRetailTerms: boolean;
  showCommonTerms: boolean;
  popupRenderKey: number;
  cancelledFromHome: boolean;
  showCountryPopup: boolean;
  languageCodes: ICountryCode[];
  currentLanguages: string[];
  performanceSelectedCurrency : string;
  rhpValue: IRhp | null;
}

export const initialState: IMansartState = {
  baseURL: "",
  tabsIndicatorColor: "",
  openContactForm: false,
  isRetailTerms: false,
  showCommonTerms: false,
  popupRenderKey: 0,
  cancelledFromHome: false,
  showCountryPopup: false,
  languageCodes: [],
  currentLanguages:[],
  performanceSelectedCurrency: 'USD',
  rhpValue: null
};
const mansartParameters = createSlice({
  name: 'mansartParameters',
  initialState,
  reducers: {
    setBaseURL: (state, action: PayloadAction<any>) => {
      state.baseURL = action.payload;
    },
    setOpenContactForm: (state, action: PayloadAction<any>) => {
      state.openContactForm = action.payload;
    },
    setIsRetailTerms: (state, action: PayloadAction<any>) => {
      state.isRetailTerms = action.payload;
    },
    setShowCommonTerms: (state, action: PayloadAction<any>) => {
      state.showCommonTerms = action.payload;
    },
    setPopupRenderKey: (state, action: PayloadAction<any>) => {
      state.popupRenderKey = action.payload;
    },
    setCancelledFromHome: (state, action: PayloadAction<any>) => {
      state.cancelledFromHome = action.payload;
    },
    setShowCountryPopup: (state, action: PayloadAction<any>) => {
      state.showCountryPopup = action.payload;
    },
    setLanguageCodes: (state, action: PayloadAction<any>) => {
      state.languageCodes = action.payload;
    },
    setCurrentLanguages: (state, action: PayloadAction<any>) => {
      state.currentLanguages = action.payload;
    },
    setPerformanceSelectedCurrency: (state, action: PayloadAction<string>) => {
      state.performanceSelectedCurrency = action.payload;
    },
    setRhpValue: (state, action: PayloadAction<IRhp>) => {
      state.rhpValue = action.payload;
    },
    resetParameters: () => ({ ...initialState })
  }
});

// actions
export const {
  setBaseURL,
  setOpenContactForm,
  setIsRetailTerms,
  setShowCommonTerms,
  setPopupRenderKey,
  setCancelledFromHome,
  setShowCountryPopup,
  setLanguageCodes,
  setCurrentLanguages,
  setPerformanceSelectedCurrency,
  setRhpValue
} = mansartParameters.actions;

// selectors
const selectSelf = state => state.mansartParameters;
export const baseURLSelector = createDraftSafeSelector(selectSelf, state => state?.baseURL);
export const openContactFormSelector = createDraftSafeSelector(selectSelf, state => state?.openContactForm);
export const isRetailTermsSelector = createDraftSafeSelector(selectSelf, state => state?.isRetailTerms);
export const showCommonTermsSelector = createDraftSafeSelector(selectSelf, state => state?.showCommonTerms);
export const popupRenderKeySelector = createDraftSafeSelector(selectSelf, state => state?.popupRenderKey);
export const cancelledFromHomeSelector = createDraftSafeSelector(selectSelf, state => state?.cancelledFromHome);
export const showCountryPopupSelector = createDraftSafeSelector(selectSelf, state => state?.showCountryPopup);
export const languageCodesSelector = createDraftSafeSelector(selectSelf, state => state?.languageCodes);
export const currentLanguagesSelector = createDraftSafeSelector(selectSelf, state => state?.currentLanguages);
export const performanceSelectedCurrencySelector = createDraftSafeSelector(selectSelf, state => state.performanceSelectedCurrency);
export const rhpValueSelector = createDraftSafeSelector(selectSelf, state => state?.rhpValue);
// reducer
export default mansartParameters.reducer;
